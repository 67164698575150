import { StripeService, useAmplitudeTracker } from '@geovelo-frontends/commons';
import { Box, CircularProgress, DialogProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';

import { AppContext } from '../../../app/context';
import { Button, Dialog } from '../../../components';
import FreeFeaturesTable from '../../../components/free-features-table';

function DowngradeFreeDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
}): JSX.Element {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    partner: { current: currentPartner, contract },
    user: { employee },
  } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useAmplitudeTracker();

  async function handleSubmit() {
    if (!currentPartner) return;

    setSubmitting(true);

    try {
      trackEvent('Offer Downgraded', {
        employee_id: employee?.id,
        partner_id: currentPartner?.id,
        partner_code: currentPartner?.code,
        user_type: 'administrator',
        offer_type: 'free',
        contract_type: contract?.contractTemplate.code,
      });

      const url = await StripeService.createPortalSession(currentPartner);

      window.open(url, '_self');
    } catch (err) {
      enqueueSnackbar(
        "Une erreur est survenue, l'offre n'a pas pu être choisie. Réessayez plus tard ou contactez notre support.",
        {
          variant: 'error',
        },
      );
      setSubmitting(false);
    }
  }

  return (
    <Dialog disableBackgroundClick fullWidth maxWidth="md" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Souhaitez-vous passer sur l'offre Gratuite ?
        </Typography>
        <Typography variant="body2">L'accès à certaines fonctionnalités sera réduit.</Typography>
        <FreeFeaturesTable />
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
            startIcon={isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />}
            variant="outlined"
          >
            Choisir l'offre Découverte
          </Button>
          <Button color="primary" onClick={() => props.onClose()} variant="contained">
            Annuler
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default DowngradeFreeDialog;
