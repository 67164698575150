import {
  Employee,
  Event,
  FMDConfig,
  Geogroup,
  Partner,
  TMobilitySurvey,
  TPartnerContract,
  TStripeProduct,
  TUserType,
  User,
  UserGeogroup,
  UserPlace,
  UserReferenceTrip,
} from '@geovelo-frontends/commons';
import { MutableRefObject, createContext, createRef } from 'react';

export type TPermissionKey =
  | 'customChallengesEnabled'
  | 'jerseysEnabled'
  | 'mobilitySurveyEnabled'
  | 'mobilitySurveyResponsesReadEnabled'
  | 'newsPostsCreationAndUpdateEnabled'
  | 'accessibilityEnabled'
  | 'advancedStatsEnabled'
  | 'fmdEnabled'
  | 'sitesEnabled'
  | 'teamsEnabled'
  | 'moreThanOneAdminAllowed';

export const offers = [
  'geovelo-entreprise-free',
  'geovelo-entreprise-trial-period',
  'geovelo-entreprise-standard',
  'geovelo-entreprise-premium',
] as const;

export const _offers: string[] = [...offers];

export type TOffer = (typeof offers)[number];

export type TPermissions = { [key in TPermissionKey]: boolean };

export const offersMap: {
  [key in TOffer]: { key: string; label: string; permissions: TPermissions };
} = {
  'geovelo-entreprise-premium': {
    key: 'premium',
    label: 'Premium 💎',
    permissions: {
      customChallengesEnabled: true,
      jerseysEnabled: true,
      mobilitySurveyEnabled: true,
      mobilitySurveyResponsesReadEnabled: true,
      newsPostsCreationAndUpdateEnabled: true,
      accessibilityEnabled: true,
      advancedStatsEnabled: true,
      fmdEnabled: true,
      sitesEnabled: true,
      teamsEnabled: true,
      moreThanOneAdminAllowed: true,
    },
  },
  'geovelo-entreprise-free': {
    key: 'free',
    label: 'Découverte ✅',
    permissions: {
      customChallengesEnabled: false,
      jerseysEnabled: false,
      mobilitySurveyEnabled: true,
      mobilitySurveyResponsesReadEnabled: false,
      newsPostsCreationAndUpdateEnabled: true,
      accessibilityEnabled: true,
      advancedStatsEnabled: false,
      fmdEnabled: false,
      sitesEnabled: true,
      teamsEnabled: false,
      moreThanOneAdminAllowed: false,
    },
  },
  'geovelo-entreprise-standard': {
    key: 'standard',
    label: 'Standard ⭐️',
    permissions: {
      customChallengesEnabled: true,
      jerseysEnabled: true,
      mobilitySurveyEnabled: true,
      mobilitySurveyResponsesReadEnabled: true,
      newsPostsCreationAndUpdateEnabled: true,
      accessibilityEnabled: true,
      advancedStatsEnabled: true,
      fmdEnabled: false,
      sitesEnabled: true,
      teamsEnabled: true,
      moreThanOneAdminAllowed: true,
    },
  },
  'geovelo-entreprise-trial-period': {
    key: 'trial',
    label: "Offre d'essai",
    permissions: {
      customChallengesEnabled: true,
      jerseysEnabled: true,
      mobilitySurveyEnabled: true,
      mobilitySurveyResponsesReadEnabled: true,
      newsPostsCreationAndUpdateEnabled: true,
      accessibilityEnabled: true,
      advancedStatsEnabled: true,
      fmdEnabled: true,
      sitesEnabled: true,
      teamsEnabled: true,
      moreThanOneAdminAllowed: true,
    },
  },
};

export const getStartedEmployeeSteps = ['work', 'profilePicture', 'team'] as const;

export type TGetStartedEmployeeSteps = (typeof getStartedEmployeeSteps)[number];

export interface IUserContext {
  current?: User | null;
  team?: UserGeogroup | null;
  employee?: Employee | null;
  getStartedProgression?: {
    allDone: boolean;
    doneCount: number;
    ignoredSteps: { [key in TGetStartedEmployeeSteps]?: boolean };
    stepsDone: { [key in TGetStartedEmployeeSteps]: boolean };
  };
  home?: UserPlace | null;
  referenceTrips?: UserReferenceTrip[];
  isRegister?: boolean;
  site?: UserGeogroup | null;
  works?: UserPlace[];
}

export const getStartedTypes = ['animation', 'fmd'] as const;

export type TGetStartedTypes = (typeof getStartedTypes)[number];

const _getStartedAdminSteps = ['logo', 'sites', 'members', 'teams', 'challenge', 'fmd'] as const;

export type TGetStartedAdminSteps = (typeof _getStartedAdminSteps)[number];

export const getStartedAdminSteps: { [key in TGetStartedTypes]: TGetStartedAdminSteps[] } = {
  animation: ['logo', 'sites', 'members', 'teams', 'challenge'],
  fmd: ['logo', 'sites', 'members', 'fmd'],
};

export interface IPartnerContext {
  current?: Partner | null;
  contract?: TPartnerContract | null;
  teams?: Geogroup[] | null;
  employeesCount?: number;
  fmdConfig?: FMDConfig | null;
  getStartedProgression?: {
    type: 'animation' | 'fmd';
    allDone: boolean;
    doneCount: number;
    ignoredSteps: { [key in TGetStartedAdminSteps]?: boolean };
    stepsDone: { [key in TGetStartedAdminSteps]: boolean };
  };
  geogroup?: Geogroup | null;
  inactiveEmployees?: Employee[] | null;
  invitationCode?: string | null;
  mobilitySurveys?: TMobilitySurvey[];
  permissions: { [key in TPermissionKey]: boolean };
  sites?: Geogroup[] | null;
  availableEvents?: { current: Event[]; future: Event[] } | null;
}

type TStripeContext = {
  products: TStripeProduct[] | undefined;
};

interface IAppActions {
  getAvailableEvents: (partner: Partner, geogroup: Geogroup) => void;
  getPartnerEmployees: (partner: Partner) => void;
  setCurrentUser: (user?: User | null) => void;
  setCurrentPartner: (partner?: Partner | null) => void;
  setPartnerContract: (contract?: TPartnerContract | null) => void;
  setPartnerInvitationCode: (code?: string | null) => void;
  setPartnerGeogroup: (geogroup?: Geogroup | null) => void;
  setPartnerTeams: (teams?: Geogroup[] | null) => void;
  setPartnerSites: (sites?: Geogroup[] | null) => void;
  setPartnerEmployeesCount: (count?: number) => void;
  setPartnerInactiveEmployees: (employees?: Employee[] | null) => void;
  setPartnerFMDConfig: (fmdConfig?: FMDConfig | null) => void;
  setPartnerMobilitySurveys: (surveys?: TMobilitySurvey[]) => void;
  setPartnerPermissions: (permissions: { [key in TPermissionKey]: boolean }) => void;
  setPartnerHasChallenges: (hasChallenge?: boolean) => void;
  setPartnerAvailableEvents: (events?: { current: Event[]; future: Event[] } | null) => void;
  setStripeProducts: (products: TStripeProduct[]) => void;
  setUserIsRegister: (isRegister?: boolean) => void;
  setUserEmployee: (employee?: Employee | null) => void;
  setUserTeam: (team?: UserGeogroup | null) => void;
  setUserHome: (home?: UserPlace | null) => void;
  setUserSite: (site?: UserGeogroup | null) => void;
  setUserReferenceTrips: (trips?: UserReferenceTrip[]) => void;
  setUserWorks: (works?: UserPlace[]) => void;
}

interface IAppContext {
  actions: IAppActions;
  history: MutableRefObject<string[] | null>;
  user: IUserContext;
  partner: IPartnerContext;
  stripe: TStripeContext;
}

export const AppContext = createContext<IAppContext>({
  history: createRef(),
  user: {},
  partner: { permissions: offersMap['geovelo-entreprise-free'].permissions },
  stripe: { products: undefined },
  actions: {
    getAvailableEvents: () => undefined,
    setStripeProducts: () => undefined,
    setCurrentUser: () => undefined,
    setCurrentPartner: () => undefined,
    setPartnerContract: () => undefined,
    setPartnerInvitationCode: () => undefined,
    setPartnerGeogroup: () => undefined,
    setPartnerTeams: () => undefined,
    setPartnerSites: () => undefined,
    getPartnerEmployees: () => undefined,
    setPartnerEmployeesCount: () => undefined,
    setPartnerInactiveEmployees: () => undefined,
    setPartnerFMDConfig: () => undefined,
    setPartnerMobilitySurveys: () => undefined,
    setPartnerPermissions: () => undefined,
    setPartnerHasChallenges: () => undefined,
    setPartnerAvailableEvents: () => undefined,
    setUserIsRegister: () => undefined,
    setUserEmployee: () => undefined,
    setUserTeam: () => undefined,
    setUserSite: () => undefined,
    setUserHome: () => undefined,
    setUserWorks: () => undefined,
    setUserReferenceTrips: () => undefined,
  },
});

export type { TUserType };
