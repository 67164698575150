import { LockIcon, TPartnerContract } from '@geovelo-frontends/commons';
import { DiamondOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

import { AnnouncementIcon } from './icons';

const now = moment();

export function PremiumBadge({
  light,
  isTry,
  disableDaysCount,
  contract,
}: {
  contract: TPartnerContract;
  disableDaysCount?: boolean;
  isTry?: boolean;
  light?: boolean;
}): JSX.Element {
  return isTry ? (
    <Box alignItems="center" display="flex" gap={1}>
      {!disableDaysCount && (
        <Box alignItems="center" display="flex" gap={1}>
          <AnnouncementIcon fontSize="small" sx={{ color: '#743AEF' }} />
          <Typography variant="body2">
            {contract.endDateTime?.diff(now, 'days', false)}j d'essai
          </Typography>
        </Box>
      )}
      <Box
        alignItems="center"
        bgcolor={light ? '#fff' : '#EAE2FC'}
        borderRadius={2}
        display="flex"
        gap={0.5}
        paddingX={1}
        paddingY={0.5}
      >
        <DiamondOutlined sx={{ fill: '#743AEF', height: 14, width: 14 }} />
        <Typography color="#743AEF" variant="caption">
          Version premium
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box
      alignItems="center"
      bgcolor={light ? '#fff' : '#EAE2FC'}
      borderRadius={2}
      display="flex"
      gap={0.5}
      justifyContent="center"
      paddingX={1}
      paddingY={0.5}
    >
      <LockIcon sx={{ color: '#743AEF', height: 14, width: 14 }} />
      <Typography color="#743AEF" variant="caption">
        Disponible en version payante
      </Typography>
    </Box>
  );
}
