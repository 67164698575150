import { LeaderboardOutlined } from '@mui/icons-material';
import { Box, DialogProps, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../app/context';
import { AnnouncementIcon, Button, CupIcon, Dialog, FMDIcon } from '../../components';
import useOfferDialog from '../../hooks/offer-dialog';

function TrialPeriodDialog({
  fromBanner,
  surveyAnswerKey,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  fromBanner?: boolean;
  onClose: () => void;
  surveyAnswerKey: string | null;
}): JSX.Element {
  const {
    partner: { current: currentPartner, contract },
  } = useContext(AppContext);
  const { t } = useTranslation();
  useOfferDialog(props.open, surveyAnswerKey);

  if (!currentPartner || !contract?.endDateTime) return <></>;

  return (
    <Dialog disableBackgroundClick fullWidth maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Bienvenue sur Geovelo Entreprise 🎉
        </Typography>
        <Typography fontWeight={700}>
          Vous bénéficiez de l'offre Premium pendant 30 jours !
        </Typography>
        <Box alignItems="center" display="flex" gap={2}>
          <Box
            alignItems="center"
            bgcolor="#ECFBF4"
            border="1px solid #CDF5E3"
            borderRadius={5}
            display="flex"
            flexShrink={0}
            height={40}
            justifyContent="center"
            width={40}
          >
            <CupIcon sx={{ color: '#0BAA70' }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>
              Lancez des challenges personnalisés ou un pack de challenges Geovelo à l'année
            </Typography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" gap={2}>
          <Box
            alignItems="center"
            bgcolor="#D9E7FF"
            border="1px solid #D9E7FF"
            borderRadius={5}
            display="flex"
            flexShrink={0}
            height={40}
            justifyContent="center"
            width={40}
          >
            <LeaderboardOutlined sx={{ color: '#326AC2' }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>Visualisez les statistiques vélo des trajets domicile-travail</Typography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" gap={2}>
          <Box
            alignItems="center"
            bgcolor="#FFF5D0"
            border="1px solid #FFECA9"
            borderRadius={5}
            display="flex"
            flexShrink={0}
            height={40}
            justifyContent="center"
            width={40}
          >
            <FMDIcon sx={{ color: '#EBAF25' }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>Accédez au Forfait Mobilités Durables vélo</Typography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" gap={2}>
          <Box
            alignItems="center"
            bgcolor="#EAE2FC"
            border="1px solid #D0BAFF"
            borderRadius={5}
            display="flex"
            flexShrink={0}
            height={40}
            justifyContent="center"
            width={40}
          >
            <AnnouncementIcon sx={{ color: '#743AEF' }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>
              Lancez une enquête mobilité pour comprendre les déplacements domicile-travail de vos
              collaborateur·ices
            </Typography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          {fromBanner && (
            <Button
              color="primary"
              component={Link}
              onClick={() => props.onClose()}
              to="/admin/offers"
              variant="outlined"
            >
              Voir les offres
            </Button>
          )}
          <Button color="primary" onClick={() => props.onClose()} variant="contained">
            {fromBanner ? 'OK' : t('commons.actions.next')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default TrialPeriodDialog;
