import isPropValid from '@emotion/is-prop-valid';
import {
  AppService,
  Employee,
  Event,
  EventService,
  FMDConfig,
  Geogroup,
  GeogroupService,
  Partner,
  PartnerService,
  TMobilitySurvey,
  TPartnerContract,
  TStripeProduct,
  User,
  UserGeogroup,
  UserPlace,
  UserReferenceTrip,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/fr';
import { Font } from '@react-pdf/renderer';
import { SnackbarProvider } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { HelmetProvider, HelmetServerState } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import NunitoBold from '../assets/fonts/NunitoSans_10pt_SemiCondensed-Black.ttf';
import NunitoItalic from '../assets/fonts/NunitoSans_7pt_SemiCondensed-Italic.ttf';
import NunitoRegular from '../assets/fonts/NunitoSans_7pt_SemiCondensed-Medium.ttf';
import { environment } from '../environment';

import {
  AppContext,
  TGetStartedAdminSteps,
  TGetStartedEmployeeSteps,
  TPermissionKey,
  getStartedAdminSteps,
  getStartedEmployeeSteps,
  offersMap,
} from './context';
import i18n from './i18n';
import Router from './router';
import theme from './theme';
import './tracking';

Font.register({
  family: 'Nunito',
  fonts: [
    { src: NunitoRegular, fontWeight: 400 },
    { src: NunitoItalic, fontWeight: 400, fontStyle: 'italic' },
    { src: NunitoBold, fontWeight: 700 },
  ],
});

const helmetContext = {} as {
  helmet: HelmetServerState;
};

function App(): JSX.Element {
  const history = useRef([]);
  const [initialized, setInitialized] = useState(false);

  // user context
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [userIsRegister, setUserIsRegister] = useState<boolean>();
  const [userGetStartedProgression, setUserGetStartedProgression] = useState<{
    allDone: boolean;
    doneCount: number;
    ignoredSteps: { [key in TGetStartedEmployeeSteps]?: boolean };
    stepsDone: { [key in TGetStartedEmployeeSteps]: boolean };
  }>();
  const [userEmployee, setUserEmployee] = useState<Employee | null>();
  const [userTeam, setUserTeam] = useState<UserGeogroup | null>();
  const [userSite, setUserSite] = useState<UserGeogroup | null>();
  const [userHome, setUserHome] = useState<UserPlace | null>();
  const [userWorks, setUserWorks] = useState<UserPlace[]>();
  const [userReferenceTrips, setUserReferenceTrips] = useState<UserReferenceTrip[]>();

  // partner context
  const [currentPartner, setCurrentPartner] = useState<Partner | null>();
  const [currentPartnerGetStartedProgression, setCurrentPartnerGetStartedProgression] = useState<{
    type: 'animation' | 'fmd';
    allDone: boolean;
    doneCount: number;
    ignoredSteps: { [key in TGetStartedAdminSteps]?: boolean };
    stepsDone: { [key in TGetStartedAdminSteps]: boolean };
  }>();
  const [currentPartnerContract, setCurrentPartnerContract] = useState<TPartnerContract | null>();
  const [currentPartnerInvitationCode, setCurrentPartnerInvitationCode] = useState<string | null>();
  const [currentPartnerGeogroup, setCurrentPartnerGeogroup] = useState<Geogroup | null>();
  const [currentPartnerTeams, setCurrentPartnerTeams] = useState<Geogroup[] | null>();
  const [currentPartnerSites, setCurrentPartnerSites] = useState<Geogroup[] | null>();
  const [currentPartnerEmployeesCount, setCurrentPartnerEmployeesCount] = useState<number>();
  const [currentPartnerInactiveEmployees, setCurrentPartnerInactiveEmployees] = useState<
    Employee[] | null
  >();
  const [currentPartnerFMDConfig, setCurrentPartnerFMDConfig] = useState<FMDConfig | null>();
  const [currentPartnerMobilitySurveys, setCurrentPartnerMobilitySurveys] =
    useState<TMobilitySurvey[]>();
  const [currentPartnerPermissions, setCurrentPartnerPermissions] = useState<{
    [key in TPermissionKey]: boolean;
  }>(offersMap['geovelo-entreprise-free'].permissions);
  const [currentPartnerHasChallenges, setCurrentPartnerHasChallenges] = useState<boolean>();
  const [currentPartnerAvailableEvents, setCurrentPartnerAvailableEvents] = useState<{
    current: Event[];
    future: Event[];
  } | null>();

  // stripe context
  const [stripeProducts, setStripeProducts] = useState<TStripeProduct[]>();

  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    AppService.environment = environment;
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (
      userEmployee?.isAdminOrAnimator &&
      currentPartner &&
      currentPartnerSites &&
      (currentPartnerTeams || !currentPartnerPermissions.teamsEnabled) &&
      currentPartnerEmployeesCount !== undefined &&
      currentPartnerHasChallenges !== undefined &&
      currentPartnerFMDConfig !== undefined
    ) {
      const type =
        userEmployee.surveyAnswers.getStartedConfigType ||
        (!currentPartnerPermissions.fmdEnabled || userEmployee.isAnimator
          ? 'animation'
          : currentPartnerFMDConfig
            ? 'fmd'
            : currentPartner.icon &&
                currentPartnerSites &&
                currentPartnerSites.length > 0 &&
                (!currentPartnerPermissions.teamsEnabled ||
                  (currentPartnerTeams && currentPartnerTeams.length > 0)) &&
                currentPartnerEmployeesCount > 1 &&
                currentPartnerHasChallenges
              ? 'animation'
              : undefined);
      if (type === 'animation' || type === 'fmd') {
        const steps = getStartedAdminSteps[type];

        const ignoredSteps = steps.reduce<{ [key in TGetStartedAdminSteps]?: boolean }>(
          (res, key) => {
            res[key] =
              key === 'teams' && !currentPartnerPermissions.teamsEnabled
                ? true
                : Boolean(userEmployee.surveyAnswers[`getStartedIgnoredSteps_${key}`]);

            return res;
          },
          {},
        );

        const stepsDone: { [key in TGetStartedAdminSteps]: boolean } = {
          logo: false,
          sites: false,
          teams: false,
          members: false,
          challenge: false,
          fmd: false,
        };

        steps.forEach((key) => {
          switch (key) {
            case 'logo':
              if (currentPartner.icon) {
                stepsDone.logo = true;
              }
              break;
            case 'sites':
              if (currentPartnerSites && currentPartnerSites.length > 0) {
                stepsDone.sites = true;
              }
              break;
            case 'teams':
              if (currentPartnerTeams && currentPartnerTeams.length > 0) {
                stepsDone.teams = true;
              }
              break;
            case 'members':
              if (currentPartnerEmployeesCount > 1) {
                stepsDone.members = true;
              }
              break;
            case 'challenge':
              if (currentPartnerHasChallenges) {
                stepsDone.challenge = true;
              }
              break;
            case 'fmd':
              if (currentPartnerFMDConfig) {
                stepsDone.fmd = true;
              }
              break;
            default:
              break;
          }
        });

        const doneCount = steps.filter((key) => stepsDone[key] && !ignoredSteps[key]).length;
        const allDone = getStartedAdminSteps[type].every(
          (key) => stepsDone[key] || ignoredSteps[key],
        );

        setCurrentPartnerGetStartedProgression({
          type,
          stepsDone,
          ignoredSteps,
          doneCount,
          allDone,
        });
      }
    }

    return () => {
      setCurrentPartnerGetStartedProgression(undefined);
    };
  }, [
    userEmployee,
    currentPartner,
    currentPartnerSites,
    currentPartnerTeams,
    currentPartnerEmployeesCount,
    currentPartnerHasChallenges,
    currentPartnerFMDConfig,
  ]);

  useEffect(() => {
    if (
      userEmployee &&
      currentPartnerTeams !== undefined &&
      currentUser &&
      userEmployee &&
      userWorks &&
      userTeam !== undefined
    ) {
      const ignoredSteps = getStartedEmployeeSteps.reduce<{
        [key in TGetStartedEmployeeSteps]?: boolean;
      }>((res, key) => {
        res[key] =
          key === 'team' && (!currentPartnerTeams || currentPartnerTeams.length === 0)
            ? true
            : Boolean(userEmployee.surveyAnswers[`getStartedEmployeeIgnoredSteps_${key}`]);

        return res;
      }, {});

      const stepsDone: { [key in TGetStartedEmployeeSteps]: boolean } = {
        work: false,
        profilePicture: false,
        team: false,
      };

      getStartedEmployeeSteps.forEach((key) => {
        switch (key) {
          case 'work':
            if (userWorks.length > 0) {
              stepsDone.work = true;
            }
            break;
          case 'profilePicture':
            if (currentUser.profilePicture) {
              stepsDone.profilePicture = true;
            }
            break;
          case 'team':
            if (userTeam) {
              stepsDone.team = true;
            }
            break;
          default:
            break;
        }
      });

      const doneCount = getStartedEmployeeSteps.filter(
        (key) => stepsDone[key] && !ignoredSteps[key],
      ).length;
      const allDone = getStartedEmployeeSteps.every((key) => stepsDone[key] || ignoredSteps[key]);

      setUserGetStartedProgression({ stepsDone, ignoredSteps, doneCount, allDone });
    }

    return () => {
      setUserGetStartedProgression(undefined);
    };
  }, [currentPartnerTeams, currentUser, userEmployee, userWorks, userTeam]);

  async function getPartnerEmployees(partner: Partner) {
    setCurrentPartnerEmployeesCount(undefined);
    setCurrentPartnerInactiveEmployees(undefined);

    try {
      const inactiveEmployees: Employee[] = [];
      const [{ count }, { count: inactiveEmployeesCount, employees }] = await cancellablePromise(
        Promise.all([
          PartnerService.getEmployees(partner, {
            page: 1,
            pageSize: 1,
            ordering: '-id',
            query: '{id}',
          }),
          PartnerService.getEmployees(partner, {
            linkStatus: 'not_linked',
            page: 1,
            pageSize: 100,
            ordering: '-id',
            query: '{id, user{username}, professional_email}',
          }),
        ]),
      );
      inactiveEmployees.push(...employees);

      if (inactiveEmployeesCount > 100) {
        const results = await Promise.all(
          new Array(Math.ceil(inactiveEmployeesCount / 100) - 1).fill(null).map((_, index) =>
            PartnerService.getEmployees(partner, {
              linkStatus: 'not_linked',
              page: index + 2,
              pageSize: 100,
              ordering: '-id',
              query: '{id, user{username}, professional_email}',
            }),
          ),
        );

        inactiveEmployees.push(...results.flatMap(({ employees }) => employees));
      }

      setCurrentPartnerEmployeesCount(count);
      setCurrentPartnerInactiveEmployees(inactiveEmployees);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setCurrentPartnerEmployeesCount(0);
        setCurrentPartnerInactiveEmployees(null);
      }
    }
  }

  async function getAvailableEvents(partner: Partner, geogroup: Geogroup) {
    try {
      const [{ count }, runningEvents, futureEvents] = await cancellablePromise(
        Promise.all([
          GeogroupService.getChallenges(geogroup.id, {
            page: 1,
            pageSize: 1,
            state: ['running', 'future'],
            order: 'start_datetime',
          }),
          EventService.getAvailableEvents({
            partner,
            geogroupId: geogroup.id,
            page: 1,
            pageSize: 3,
            state: ['running'],
            automaticSubscription: 'CHALLENGE_EDITORIALIZED_SUBSCRIBER',
          }),
          EventService.getAvailableEvents({
            partner,
            geogroupId: geogroup.id,
            page: 1,
            pageSize: 3,
            state: ['future'],
            automaticSubscription: 'CHALLENGE_EDITORIALIZED_SUBSCRIBER',
          }),
        ]),
      );

      setCurrentPartnerHasChallenges(count > 0);
      setCurrentPartnerAvailableEvents({ current: runningEvents, future: futureEvents });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  if (!initialized) return <></>;

  return (
    <AppContext.Provider
      value={{
        history,
        user: {
          current: currentUser,
          isRegister: userIsRegister,
          getStartedProgression: userGetStartedProgression,
          employee: userEmployee,
          team: userTeam,
          site: userSite,
          home: userHome,
          works: userWorks,
          referenceTrips: userReferenceTrips,
        },
        partner: {
          current: currentPartner,
          getStartedProgression: currentPartnerGetStartedProgression,
          contract: currentPartnerContract,
          invitationCode: currentPartnerInvitationCode,
          geogroup: currentPartnerGeogroup,
          teams: currentPartnerTeams,
          sites: currentPartnerSites,
          employeesCount: currentPartnerEmployeesCount,
          inactiveEmployees: currentPartnerInactiveEmployees,
          fmdConfig: currentPartnerFMDConfig,
          mobilitySurveys: currentPartnerMobilitySurveys,
          permissions: currentPartnerPermissions,
          availableEvents: currentPartnerAvailableEvents,
        },
        stripe: { products: stripeProducts },
        actions: {
          setStripeProducts,
          setCurrentUser,
          setCurrentPartner,
          setPartnerContract: setCurrentPartnerContract,
          setPartnerInvitationCode: setCurrentPartnerInvitationCode,
          setPartnerGeogroup: setCurrentPartnerGeogroup,
          setPartnerTeams: setCurrentPartnerTeams,
          setPartnerSites: setCurrentPartnerSites,
          setPartnerEmployeesCount: setCurrentPartnerEmployeesCount,
          setPartnerInactiveEmployees: setCurrentPartnerInactiveEmployees,
          setPartnerFMDConfig: setCurrentPartnerFMDConfig,
          setPartnerMobilitySurveys: setCurrentPartnerMobilitySurveys,
          setPartnerPermissions: setCurrentPartnerPermissions,
          setPartnerHasChallenges: setCurrentPartnerHasChallenges,
          setPartnerAvailableEvents: setCurrentPartnerAvailableEvents,
          setUserIsRegister,
          setUserEmployee,
          setUserTeam,
          setUserSite,
          setUserHome,
          setUserWorks,
          setUserReferenceTrips,
          getPartnerEmployees,
          getAvailableEvents,
        },
      }}
    >
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <StyleSheetManager
            shouldForwardProp={(propName, elementToBeRendered) =>
              typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
            }
          >
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              maxSnack={3}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <HelmetProvider context={helmetContext}>
                  <BrowserRouter>
                    <Router
                      cancellablePromise={cancellablePromise}
                      cancelPromises={cancelPromises}
                    />
                  </BrowserRouter>
                </HelmetProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </StyleSheetManager>
        </ThemeProvider>
      </I18nextProvider>
    </AppContext.Provider>
  );
}

export default App;
