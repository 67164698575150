import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

import icon from '../assets/img/plotty-find.svg';

function EmptyState({ text, actions }: { actions?: ReactNode; text: ReactNode }): JSX.Element {
  return (
    <Box alignItems="center" display="flex" flexDirection="column" gap={2} paddingY={10}>
      <img src={icon} width="80px" />
      <Typography fontWeight={600} variant="h6">
        Oups...
      </Typography>
      <Typography align="center">{text}</Typography>
      {actions}
    </Box>
  );
}

export default EmptyState;
