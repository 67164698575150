import { Box, DialogProps, Typography } from '@mui/material';
import moment from 'moment';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../../app/context';
import { Button, Dialog } from '../../components';
import FreeFeaturesTable from '../../components/free-features-table';
import useOfferDialog from '../../hooks/offer-dialog';

const now = moment();

function TrialPeriodEndSoonDialog({
  surveyAnswerKey,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  surveyAnswerKey: string | null;
}): JSX.Element {
  const {
    partner: { contract },
  } = useContext(AppContext);
  useOfferDialog(props.open, surveyAnswerKey);

  if (!contract?.endDateTime) return <></>;

  const remainingDays = contract.endDateTime.diff(now, 'days', false);

  return (
    <Dialog disableBackgroundClick fullWidth maxWidth="md" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Votre période d'essai expire dans {remainingDays} {remainingDays > 1 ? 'jours' : 'jour'}{' '}
          ⏰
        </Typography>
        <Typography variant="body2">
          Vous allez être basculé sur l'offre Gratuite, et l'accès aux fonctionnalités va être
          limité. Choisissez une offre Standard ou Premium pour en garder l'accès.
        </Typography>
        <FreeFeaturesTable />
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button
            color="primary"
            component={Link}
            onClick={() => props.onClose()}
            to="/admin/offers"
            variant="contained"
          >
            Voir les offres
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default TrialPeriodEndSoonDialog;
