import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ChevronLeftIcon } from '../../components';

import Banner from './banner';

function PageLayout({
  fullHeight,
  headerImage,
  backPath,
  backPathState,
  title,
  children,
  actions,
  onBackButtonClick,
}: {
  actions?: ReactNode;
  backPath?: string;
  backPathState?: object;
  children?: ReactNode;
  fullHeight?: boolean;
  headerImage?: string;
  onBackButtonClick?: () => void;
  title?: ReactNode;
}): JSX.Element {
  const { t } = useTranslation();

  const hasHeaderImage = headerImage !== undefined;

  return (
    <Box
      bgcolor="#F6F8FC"
      display={fullHeight ? 'flex' : undefined}
      flexDirection={fullHeight ? 'column' : undefined}
      minHeight="100%"
      paddingBottom={hasHeaderImage ? 8 : 0}
      position="relative"
    >
      <Banner />
      {hasHeaderImage && (
        <Box
          height={{ xs: 200, sm: 300, md: 400, lg: 500 }}
          sx={{
            backgroundColor: '#E3E7EE',
            backgroundImage: `url(${headerImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          width="100%"
        />
      )}
      <Box
        bgcolor={hasHeaderImage ? '#fff' : undefined}
        borderRadius={hasHeaderImage ? 6 : 0}
        display={fullHeight ? 'flex' : undefined}
        flexDirection={fullHeight ? 'column' : undefined}
        flexGrow={fullHeight ? 1 : undefined}
        marginTop={hasHeaderImage ? { xs: '-50px', sm: '-100px', md: '-200px', lg: '-250px' } : 0}
        marginX="auto"
        maxWidth={
          hasHeaderImage
            ? { xs: 'calc(100% - 48px)', md: 'calc(100% - 128px)' }
            : 'calc(100% - 48px)'
        }
        paddingX={hasHeaderImage ? { xs: 3, md: 5, lg: 8 } : 0}
        paddingY={hasHeaderImage ? { xs: 3, md: 5, lg: 8 } : { xs: 3, sm: 5 }}
        width={1040}
      >
        <Box
          alignItems="start"
          display="flex"
          flexDirection="column"
          flexGrow={fullHeight ? 1 : undefined}
          gap={3}
        >
          <Box
            alignItems={{ xs: 'stretch', md: 'center' }}
            alignSelf="stretch"
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={2}
            minHeight={48}
          >
            <Box alignItems="center" display="flex" flexGrow={1} gap={2}>
              {backPath ? (
                <Box width={48}>
                  <Tooltip title={t('commons.actions.go_back')}>
                    <IconButton component={Link} state={backPathState} to={backPath}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                onBackButtonClick && (
                  <Box width={48}>
                    <Tooltip title={t('commons.actions.go_back')}>
                      <IconButton onClick={onBackButtonClick}>
                        <ChevronLeftIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )
              )}
              {title && (
                <Typography component="h1" fontWeight={600} variant="h5">
                  {title}
                </Typography>
              )}
            </Box>
            {actions && (
              <Box
                alignItems="center"
                display="flex"
                flexShrink={0}
                gap={2}
                justifyContent="flex-end"
              >
                {actions}
              </Box>
            )}
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default PageLayout;
