import { Box, DialogProps, Typography } from '@mui/material';

import { Dialog } from '../../components';
import useOfferDialog from '../../hooks/offer-dialog';
import OffersTable from '../../pages/admin/offers/table';

function TrialPeriodEndedDialog({
  surveyAnswerKey,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  surveyAnswerKey: string | null;
}): JSX.Element {
  useOfferDialog(props.open, surveyAnswerKey);

  return (
    <Dialog fullWidth maxWidth="lg" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Votre période d'essai de 30 jours a expiré ⏰
        </Typography>
        <Typography variant="body2">
          L'accès aux fonctionnalités est maintenant limité. Choisissez une offre Standard ou
          Premium pour en garder l'accès.
        </Typography>
        <OffersTable />
      </Box>
    </Dialog>
  );
}

export default TrialPeriodEndedDialog;
