import { Check } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

import illu from '../../assets/img/onboarding-illu.svg';

function Steps({
  items,
  currentStepIndex,
}: {
  currentStepIndex: number;
  items: Array<{ key: string; label: ReactNode; optional?: boolean }>;
}): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1} width="100%">
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={3}
        justifyContent="center"
        paddingX={{ xs: 5, lg: 8 }}
        paddingY={5}
      >
        {items.map(({ key, label, optional }, index) => (
          <Item
            currentStepIndex={currentStepIndex}
            index={index}
            key={key}
            label={label}
            optional={optional}
          />
        ))}
      </Box>
      <Box
        height={200}
        sx={{
          backgroundImage: `url(${illu})`,
          backgroundPosition: 'bottom left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
    </Box>
  );
}

function Item({
  currentStepIndex,
  index,
  optional,
  label,
}: {
  currentStepIndex: number;
  index: number;
  label: ReactNode;
  optional?: boolean;
}): JSX.Element {
  return (
    <Box
      alignItems="center"
      display="flex"
      gap={2}
      sx={{ opacity: currentStepIndex !== index ? 0.5 : 1 }}
    >
      <Box
        alignItems="center"
        border="1px solid #fff"
        borderRadius={4}
        color="#fff"
        display="flex"
        height={32}
        justifyContent="center"
        width={32}
      >
        {currentStepIndex > index ? (
          <Check sx={{ height: 16, width: 16 }} />
        ) : (
          <Typography color="#fff">{index + 1}</Typography>
        )}
      </Box>
      <Typography color="#fff">
        {label}
        {optional ? (
          <>
            {' '}
            <Typography component="i" variant="caption">
              (facultatif)
            </Typography>
          </>
        ) : (
          <></>
        )}
      </Typography>
    </Box>
  );
}

export default Steps;
