import { LeaderboardOutlined } from '@mui/icons-material';
import { Box, DialogProps, Typography } from '@mui/material';
import { useContext } from 'react';

import { AppContext } from '../../app/context';
import { Button, CupIcon, Dialog, FMDIcon, JerseyIcon } from '../../components';

function WelcomeDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }): JSX.Element {
  const {
    partner: { fmdConfig },
  } = useContext(AppContext);

  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Bienvenue sur Geovelo Entreprise 🎉
        </Typography>
        <Box alignItems="center" display="flex" gap={2}>
          <Box
            alignItems="center"
            bgcolor="#FFF5D0"
            border="1px solid #FFECA9"
            borderRadius={5}
            display="flex"
            flexShrink={0}
            height={40}
            justifyContent="center"
            width={40}
          >
            <CupIcon sx={{ color: '#BB8A1A' }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>Partipez à des challenges avec vos collègues</Typography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" gap={2}>
          <Box
            alignItems="center"
            bgcolor="#FCE3FF"
            border="1px solid #EFC3F5"
            borderRadius={5}
            display="flex"
            flexShrink={0}
            height={40}
            justifyContent="center"
            width={40}
          >
            <JerseyIcon sx={{ color: '#B235C6' }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>Tentez de gagner un des trois maillots</Typography>
          </Box>
        </Box>
        {fmdConfig && (
          <Box alignItems="center" display="flex" gap={2}>
            <Box
              alignItems="center"
              bgcolor="#F3F6FF"
              border="1px solid #D9E7FF"
              borderRadius={5}
              display="flex"
              flexShrink={0}
              height={40}
              justifyContent="center"
              width={40}
            >
              <FMDIcon sx={{ color: '#326AC2' }} />
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography>
                Accédez au calendrier de votre Forfait Mobilités Durables vélo
              </Typography>
            </Box>
          </Box>
        )}
        <Box alignItems="center" display="flex" gap={2}>
          <Box
            alignItems="center"
            bgcolor="#ECFBF4"
            border="1px solid #96E2C1"
            borderRadius={5}
            display="flex"
            flexShrink={0}
            height={40}
            justifyContent="center"
            width={40}
          >
            <LeaderboardOutlined sx={{ color: '#2AC682' }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>
              Suivez les statistiques de trajets vélo domicile-travail et celles de votre entreprise
            </Typography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button color="primary" onClick={() => props.onClose()} variant="contained">
            C'est parti !
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default WelcomeDialog;
