import { EmployeeService } from '@geovelo-frontends/commons';
import { Check } from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  CircularProgress,
  DialogProps,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext, TGetStartedTypes, getStartedTypes } from '../../app/context';
import { Button, CupIcon, Dialog, FMDIcon } from '../../components';

const typesMap: {
  [key in TGetStartedTypes]: { Icon: (props: SvgIconProps) => JSX.Element; label: string };
} = {
  animation: { Icon: CupIcon, label: 'Créer un challenge pour encourager la pratique du vélo' },
  fmd: {
    Icon: FMDIcon,
    label: "Mettre en place l'indemnité vélo avec le Forfait Mobilités Durables",
  },
};

function GetStartedConfigTypeDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
}): JSX.Element {
  const [selectedType, selectType] = useState<TGetStartedTypes>('animation');
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    partner: { current: currentPartner },
    user: { employee },
    actions: { setUserEmployee },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { transitions } = useTheme();

  async function handleSubmit() {
    if (!currentPartner || !employee) return;

    setSubmitting(true);

    try {
      const updatedEmployee = await EmployeeService.updateEmployee(currentPartner, employee.id, {
        surveyAnswers: { ...employee.surveyAnswers, getStartedConfigType: selectedType },
      });

      setUserEmployee(updatedEmployee);
      props.onClose();
      setTimeout(() => navigate('/get-started'), transitions.duration.leavingScreen);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  }

  return (
    <Dialog disableBackgroundClick disableCloseButton fullWidth maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Que souhaitez-vous faire en premier ?
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          {getStartedTypes.map((key) => {
            const { Icon, label } = typesMap[key];
            const active = selectedType === key;

            return (
              <Box
                component={ButtonBase}
                disabled={isSubmitting}
                key={key}
                onClick={() => selectType(key)}
                sx={{
                  alignItems: 'flex-start',
                  bgcolor: active ? '#ECFBF4' : isSubmitting ? '#F6F8FC' : 'transparent',
                  border:
                    !active && isSubmitting ? 0 : `1px solid ${active ? '#2AC682' : '#DDE0E7'}`,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  justifyContent: 'flex-start',
                  opacity: !active && isSubmitting ? 0.6 : 1,
                  padding: 1,
                  textAlign: 'left',
                }}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                  gap={1}
                  padding={1}
                >
                  <Icon color={isSubmitting ? 'action' : 'primary'} />
                  <Typography fontWeight={700} variant="body2">
                    {label}
                  </Typography>
                </Box>
                <Box flexShrink={0} height={20} visibility={active ? 'visible' : 'hidden'}>
                  <Check fontSize="small" sx={{ color: '#2AC682' }} />
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
            startIcon={isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />}
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default GetStartedConfigTypeDialog;
