import { StripeService, useAmplitudeTracker } from '@geovelo-frontends/commons';
import { Check } from '@mui/icons-material';
import { Box, Divider, Switch, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext, offers, offersMap } from '../../../app/context';
import { Button } from '../../../components';

import DowngradeFreeDialog from './downgrade-free-dialog';
import DowngradeStandardDialog from './downgrade-standard-dialog';

const sizeRangesKeys = [
  'companyBetween1and100',
  'companyBetween101and500',
  'companyMoreThan500',
] as const;

type TSizeRange = (typeof sizeRangesKeys)[number];

const sizeRanges: Array<{ key: TSizeRange; min: number; max?: number }> = [
  { key: 'companyBetween1and100', min: 0, max: 100 },
  { key: 'companyBetween101and500', min: 101, max: 500 },
  { key: 'companyMoreThan500', min: 501 },
];

function OffersTable(): JSX.Element {
  const [sizeRangeIndex, setSizeRangeIndex] = useState<number>();
  const [isSubmitting, setSubmitting] = useState(false);
  const [periodType, setPeriodType] = useState<'month' | 'year'>('year');
  const [downgradeFreeDialogOpen, openDowngradeFreeDialog] = useState(false);
  const [downgradeStandardDialogOpen, openDowngradeStandardDialog] = useState(false);
  const {
    partner: { current: currentPartner, contract },
    user: { employee },
    stripe: { products },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    if (currentPartner) {
      const companyMaxSize = currentPartner.companyMaxSize || 0;
      const index = sizeRanges.findIndex(
        ({ min, max }) => min <= companyMaxSize && (max || Number.MAX_VALUE) >= companyMaxSize,
      );
      setSizeRangeIndex(index !== -1 ? index : 0);
    }
  }, [currentPartner]);

  async function handleOfferSelected(offerKey: string, priceId: string) {
    if (!currentPartner || !contract || isSubmitting || !priceId) return;

    setSubmitting(true);

    const { code: activeContract } = contract.contractTemplate;
    if (
      priceId === 'free' &&
      (activeContract === 'geovelo-entreprise-trial-period' ||
        activeContract === 'geovelo-entreprise-free')
    ) {
      return;
    }

    try {
      trackEvent('Offer Selected', {
        employee_id: employee?.id,
        partner_id: currentPartner?.id,
        partner_code: currentPartner?.code,
        user_type: 'administrator',
        offer_type: offerKey,
        contract_type: contract?.contractTemplate.code,
      });

      if (
        activeContract === 'geovelo-entreprise-trial-period' ||
        activeContract === 'geovelo-entreprise-free'
      ) {
        const url = await StripeService.createCheckoutSession({
          partnerCode: currentPartner.code,
          priceId,
        });

        window.open(url, '_self');
      } else {
        const url = await StripeService.createPortalSession(currentPartner);

        window.open(url, '_self');
      }
    } catch (err) {
      enqueueSnackbar(
        "Une erreur est survenue, l'offre n'a pas pu être choisie. Réessayez plus tard ou contactez notre support.",
        {
          variant: 'error',
        },
      );
      setSubmitting(false);
    }
  }

  if (sizeRangeIndex === undefined || !contract || !products) return <></>;

  const sizeRange = sizeRanges[sizeRangeIndex];
  const {
    contractTemplate: { code: activeContract },
  } = contract;

  return (
    <>
      <Box
        alignItems={{ xs: 'flex-start', md: 'center' }}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={2}
        justifyContent="flex-end"
      >
        <Box alignItems="center" display="flex" gap={1}>
          <Box alignItems="center" display="flex" gap={1}>
            <Typography fontWeight={700} variant="body2">
              {t('companies.pages.admin.company.offers.payments_year')}
            </Typography>
            <Box
              alignItems="center"
              bgcolor="#ECFBF4"
              borderRadius={3}
              display="flex"
              height={32}
              paddingX={1}
            >
              <Typography color="#048760" variant="caption">
                {t('companies.pages.admin.company.offers.free_months', { count: 2 })}
              </Typography>
            </Box>
          </Box>
          <Switch
            checked={periodType === 'month'}
            onChange={() => setPeriodType(periodType === 'month' ? 'year' : 'month')}
            sx={{
              '.MuiSwitch-track': { backgroundColor: '#326ac2', opacity: 0.5 },
              '.MuiSwitch-thumb': { color: '#326ac2' },
            }}
          />
          <Typography fontWeight={700} variant="body2">
            {t('companies.pages.admin.company.offers.payments_month')}
          </Typography>
        </Box>
      </Box>
      <Box alignItems="stretch" display="flex" flexWrap="wrap" gap={4}>
        {offers
          .filter((key) => key !== 'geovelo-entreprise-trial-period')
          .map((key) => {
            const { key: offerKey, label } = offersMap[key];

            const product = products.find(({ contractCode }) => contractCode === key);
            if (!product) return <Fragment key={key} />;

            const { description: productDescription, prices, features } = product;

            const productPrice = prices?.find(
              (price) => price.periodType === periodType && price.companyMaxSize === sizeRange?.max,
            );

            const active = key === activeContract;

            return (
              <Box
                bgcolor={active ? '#F6F8FC' : ''}
                border={`2px solid ${active ? '#D9E7FF' : '#048760'}`}
                borderRadius={4}
                display="flex"
                flexDirection="column"
                gap={3}
                key={key}
                padding={4}
                width={{ xs: '100%', lg: 'calc((100% - 64px) / 3)' }}
              >
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box alignItems="center" display="flex" gap={1}>
                    <Typography color="primary" fontSize="1.1rem" fontWeight={700}>
                      {label}
                    </Typography>
                    {key === 'geovelo-entreprise-premium' &&
                      activeContract === 'geovelo-entreprise-trial-period' && (
                        <Box
                          alignItems="center"
                          bgcolor="#EAE2FC"
                          borderRadius={3}
                          display="flex"
                          height={32}
                          paddingX={1}
                        >
                          <Typography color="#905EFA" variant="caption">
                            {t('companies.pages.admin.company.offers.trial_in_progress')}
                          </Typography>
                        </Box>
                      )}
                  </Box>
                  <Box display="flex" flexDirection="column" gap={1}>
                    {productPrice || key === 'geovelo-entreprise-free' ? (
                      <Box display="flex" flexDirection="column">
                        <Typography fontWeight={600} variant="body2">
                          <Typography component="span" fontWeight={700} variant="h4">
                            {periodType === 'year'
                              ? Math.ceil((productPrice?.price || 0) / 12)
                              : productPrice?.price || 0}{' '}
                            €
                          </Typography>
                          {productPrice?.price ? ` HT ` : ' '}/{' '}
                          {t(`commons.periods.month`).toLowerCase()}
                        </Typography>
                        {periodType === 'year' && (
                          <Typography fontWeight={600} variant="body2">
                            {productPrice?.price
                              ? `Soit ${productPrice?.price} € HT / an`
                              : '0 € / an'}
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      <Typography fontWeight={700} variant="h6">
                        {t('companies.pages.admin.company.offers.price_on_quote')}
                      </Typography>
                    )}
                    <Typography color="textSecondary" minHeight={{ lg: 40 }} variant="body2">
                      {productDescription}
                    </Typography>
                  </Box>
                  <Box>
                    {active ? (
                      <Button
                        disabled
                        fullWidth
                        sx={
                          !isSubmitting
                            ? {
                                '&&': {
                                  bgcolor: '#D9E7FF',
                                  color: theme.palette.primary.main,
                                  opacity: 1,
                                },
                              }
                            : {}
                        }
                        variant="contained"
                      >
                        {t('companies.pages.admin.company.offers.actions.current_offer')}
                      </Button>
                    ) : productPrice || key === 'geovelo-entreprise-free' ? (
                      <Button
                        color="primary"
                        disabled={
                          isSubmitting ||
                          (activeContract === 'geovelo-entreprise-trial-period' &&
                            key === 'geovelo-entreprise-free')
                        }
                        onClick={() => {
                          if (
                            (activeContract === 'geovelo-entreprise-premium' ||
                              activeContract === 'geovelo-entreprise-standard') &&
                            key === 'geovelo-entreprise-free'
                          ) {
                            openDowngradeFreeDialog(true);
                            return;
                          }

                          if (
                            activeContract === 'geovelo-entreprise-premium' &&
                            key === 'geovelo-entreprise-standard'
                          ) {
                            openDowngradeStandardDialog(true);
                            return;
                          }

                          handleOfferSelected(
                            offerKey,
                            key === 'geovelo-entreprise-free' ? 'free' : productPrice?.id || '',
                          );
                        }}
                        variant="contained"
                      >
                        {t('companies.pages.admin.company.offers.actions.choose_offer', {
                          context: offerKey,
                        })}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        component="a"
                        disabled={isSubmitting}
                        href="https://meetings-eu1.hubspot.com/apivert/plateforme"
                        rel="noreferrer"
                        target="_blank"
                        variant="contained"
                      >
                        {t('companies.pages.admin.company.offers.actions.be_contacted')}
                      </Button>
                    )}
                  </Box>
                  {(key === 'geovelo-entreprise-standard' ||
                    key === 'geovelo-entreprise-premium' ||
                    features.length > 0) && (
                    <>
                      <Divider />
                      <Box display="flex" flexDirection="column" gap={2}>
                        {(key === 'geovelo-entreprise-standard' ||
                          key === 'geovelo-entreprise-premium') && (
                          <Typography fontWeight={700} variant="body2">
                            {t('companies.pages.admin.company.offers.all_features', {
                              context: offerKey,
                            })}
                          </Typography>
                        )}
                        {features.map((feature, index) => (
                          <Box alignItems="center" display="flex" gap={1} key={index}>
                            <Check sx={{ color: '#2AC682' }} />
                            <Typography variant="body2">{feature}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            );
          })}
      </Box>
      <DowngradeFreeDialog
        onClose={() => openDowngradeFreeDialog(false)}
        open={downgradeFreeDialogOpen}
      />
      <DowngradeStandardDialog
        onClose={() => openDowngradeStandardDialog(false)}
        open={downgradeStandardDialogOpen}
      />
    </>
  );
}

export default OffersTable;
