import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../app/context';
import EmptyState from '../../components/empty-state';
import PageLayout from '../../layouts/page';

function CompanyDeactivatedPage(): JSX.Element {
  const {
    partner: { geogroup },
    user: { employee },
  } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!employee) return;

    if (employee.isAdmin || !geogroup || geogroup.publicationStatus === 'published') navigate('/');
  }, [employee, geogroup]);

  if (employee?.isAdmin || !geogroup || geogroup.publicationStatus === 'published') return <></>;

  return (
    <PageLayout>
      <Box alignSelf="stretch">
        <EmptyState
          text={
            <Trans
              components={[<br key={0} />]}
              i18nKey="companies.pages.company_deactivated.description"
            />
          }
        />
      </Box>
    </PageLayout>
  );
}

export default CompanyDeactivatedPage;
