import { SavedCO2Icon } from '@geovelo-frontends/commons';
import { Box, SvgIconProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

import mockup from '../../assets/img/mockup.png';
import { CupIcon, FMDIcon, StatsIcon } from '../../components';

function BringTogether(): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      paddingX={{ xs: 5, lg: 8 }}
      paddingY={5}
      width="100%"
    >
      <Typography color="#fff" fontSize="1.15rem" fontWeight={600}>
        Rassemblez vos équipes autour du vélo !
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <Item Icon={StatsIcon} text="Statistiques vélotaf" />
        <Item Icon={SavedCO2Icon} text="Mesure de l'impact carbone" />
        <Item Icon={CupIcon} text="Challenges" />
        <Item Icon={FMDIcon} text="Forfait Mobilités Durables" />
      </Box>
      <img src={mockup} style={{ maxWidth: 500 }} width="100%" />
    </Box>
  );
}

function Item({
  Icon,
  text,
}: {
  Icon: (props: SvgIconProps) => JSX.Element;
  text: ReactNode;
}): JSX.Element {
  return (
    <Box alignItems="center" display="flex" gap={2}>
      <Box
        alignItems="center"
        bgcolor="#3177BA"
        borderRadius={4}
        color="#fff"
        display="flex"
        height={32}
        justifyContent="center"
        width={32}
      >
        <Icon sx={{ height: 16, width: 16 }} />
      </Box>
      <Typography color="#fff">{text}</Typography>
    </Box>
  );
}

export default BringTogether;
