import { InfoOutlined, SvgIconComponent } from '@mui/icons-material';
import { Box, SvgIconProps, Typography } from '@mui/material';
import { ReactNode, useContext } from 'react';

import { AppContext } from '../app/context';

import { Card } from './card';
import { HelpIcon } from './icons';
import { PremiumBadge } from './premium-badge';

export function NewInfoCard({
  row,
  variant,
  size,
  illustration,
  Icon,
  title,
  description,
  action,
}: {
  action?: ReactNode;
  description?: string;
  Icon?: ((props: SvgIconProps) => JSX.Element) | SvgIconComponent;
  illustration?: string;
  row?: boolean;
  size?: 'small';
  title?: string;
  variant?: 'try' | 'info' | 'warn' | 'success';
}): JSX.Element {
  const {
    partner: { contract },
  } = useContext(AppContext);

  return (
    <Card
      disableDivider
      sx={{
        border:
          variant === 'warn'
            ? '1px solid #FFE27D'
            : variant === 'success'
              ? '1px solid #96E2C1'
              : undefined,
        bgcolor:
          variant === 'try'
            ? '#FFF5D0'
            : variant === 'info'
              ? '#F3F6FF'
              : variant === 'warn'
                ? '#FFF5D0'
                : variant === 'success'
                  ? '#ECFBF4'
                  : undefined,
        width: '100%',
      }}
    >
      <Box alignItems="flex-start" display="flex" flexDirection="row" gap={2} padding={3}>
        {illustration && <img src={illustration} style={{ flexShrink: 0 }} width={80} />}
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection={row ? 'row' : 'column'}
          flexGrow={1}
          gap={2}
        >
          {variant === 'try'
            ? contract && <PremiumBadge isTry light contract={contract} />
            : variant === 'info'
              ? !Icon && !illustration && <InfoOutlined color="primary" />
              : !description && <Avatar Icon={Icon} variant={variant} />}
          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={row ? 1 : undefined}
              gap={
                row ? 0 : (variant === 'info' && Icon) || (variant !== 'try' && description) ? 2 : 1
              }
            >
              {title && (
                <Box
                  alignItems="center"
                  display="flex"
                  gap={(variant === 'info' && Icon) || (variant !== 'try' && description) ? 2 : 1}
                >
                  {variant === 'info'
                    ? Icon && <Icon color="primary" />
                    : variant !== 'try' && description && <Avatar Icon={Icon} variant={variant} />}
                  <Typography
                    fontWeight={700}
                    variant={variant === 'try' ? 'h6' : size === 'small' ? 'body2' : 'body1'}
                  >
                    {title}
                  </Typography>
                </Box>
              )}
              {description && (
                <Typography variant={size === 'small' ? 'body2' : 'body1'}>
                  {description}
                </Typography>
              )}
            </Box>
            {action && <Box>{action}</Box>}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

function Avatar({
  variant,
  Icon,
}: {
  Icon?: ((props: SvgIconProps) => JSX.Element) | SvgIconComponent;
  variant?: 'try' | 'info' | 'warn' | 'success';
}): JSX.Element {
  return (
    <Box
      alignItems="center"
      bgcolor={variant === 'warn' ? '#FFE27D' : variant === 'success' ? '#96E2C1' : '#FFF5D0'}
      borderRadius={5}
      color={variant === 'success' ? '#048760' : '#BB8A1A'}
      display="flex"
      flexShrink={0}
      height={40}
      justifyContent="center"
      width={40}
    >
      {Icon ? <Icon color="inherit" /> : <HelpIcon color="inherit" />}
    </Box>
  );
}
