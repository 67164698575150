import { SvgIcon, SvgIconProps } from '@mui/material';

export function JerseyIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 16 14" {...props}>
      <path
        d="M10.2124 1.10156L14.637 2.57642V6.26357H12.4247V12.163C12.4247 12.3586 12.347 12.5462 12.2087 12.6845C12.0705 12.8228 11.8829 12.9004 11.6873 12.9004H4.313C4.11742 12.9004 3.92986 12.8228 3.79156 12.6845C3.65327 12.5462 3.57557 12.3586 3.57557 12.163V6.26357H1.36328V2.57642L5.78786 1.10156C5.78786 1.6883 6.02094 2.251 6.43583 2.66589C6.85071 3.08077 7.41342 3.31385 8.00015 3.31385C8.58689 3.31385 9.14959 3.08077 9.56448 2.66589C9.97936 2.251 10.2124 1.6883 10.2124 1.10156Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.60894"
      />
    </SvgIcon>
  );
}
